import React from "react";
import NotFoundComponent from "../components/NotFoundComponent";
import PublicLayout from "../layout/PublicLayout";

const NotFoundPage = () => (
  <PublicLayout fullHeight noFooter seoTitle="Page Not Found">
    <NotFoundComponent />
  </PublicLayout>
);

export default NotFoundPage;
